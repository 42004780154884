import { TextFieldModule } from '@angular/cdk/text-field';
import { NgFor } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { SubmitProductReviewUseCase } from 'src/app/core/usecases/products/submit-product-review.usecase';
import { PRODUCT_REVIEW_OPTIONS } from 'src/app/presentation/shared/constants/product-review';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { SiteTranslateService } from '../../shared/services/translate.service';

@Component({
  selector: 'app-review-product-dialog',
  templateUrl: './review-product-dialog.component.html',
  styleUrls: ['./review-product-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    TextFieldModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
})
export class ReviewProductDialogComponent implements OnInit {
  public reviewForm: UntypedFormGroup;

  public submitted = false;

  public ratingArr: any[] = [];

  public listOfIssuesWithProduct = PRODUCT_REVIEW_OPTIONS;

  isEnglishLanguage = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _toastService: ToastrService,
    private _translateService: TranslateService,
    private dialogRef: MatDialogRef<ReviewProductDialogComponent>,
    private _submitProductReviewUseCase: SubmitProductReviewUseCase,
    private _siteTranslationService: SiteTranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      productId: string;
    },
  ) {}

  ngOnInit(): void {
    this.isEnglishLanguage = this._siteTranslationService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this.initializeForm();
    this.setRatingArray();
  }

  setRatingArray(): void {
    for (let index = 0; index < 5; index++) {
      this.ratingArr.push(index);
    }
  }

  initializeForm(): void {
    this.reviewForm = this._formBuilder.group({
      reasons: null,
      content: null,
      priceRating: null,
      productRating: null,
    });
  }

  get formControl(): any {
    return this.reviewForm.controls;
  }

  setRatingValue(key: 'priceRating' | 'productRating', value: number): void {
    this.reviewForm.get(key)!.setValue(value);
  }

  showIcon(key: 'priceRating' | 'productRating', index: number): string {
    const ratingValue = this.reviewForm.get(key)!.value;
    if (ratingValue >= index + 1) {
      return 'star';
    }
    return 'star_border';
  }

  onSubmit(): void {
    const data: any = {
      priceRating: this.formControl.priceRating.value,
      productRating: this.formControl.productRating.value,
      content: this.formControl.content.value,
      reasons: this.formControl.reasons.value,
    };
    if (!data.content && !data.reasons && !data.priceRating && !data.productRating) {
      this._toastService.error(
        this._translateService.instant('PRODUCTS_PAGE.REVIEW_PRODUCT.FIELD_REQUIRED'),
      );
    } else {
      const filteredData = Object.keys(data)
        .filter((key) => data[key])
        .reduce((resultObject: any, key) => {
          resultObject[key] = data[key];
          return resultObject;
        }, {});
      const params = {
        data: filteredData,
        productId: this.data.productId,
      };
      this.submitted = true;
      this._submitProductReviewUseCase
        .execute(params)
        .pipe(
          finalize(() => {
            this.submitted = false;
          }),
        )
        .subscribe({
          next: (_) => {
            this._toastService.success(
              this._translateService.instant('PRODUCTS_PAGE.REVIEW_PRODUCT.CONFIRMATION_SUCCESS'),
            );
            this.onClose();
          },
          error: (_) => {
            this._toastService.error(
              this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE'),
            );
          },
        });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
