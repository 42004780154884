<section class="review-form">
  <span class="title">{{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.REVIEW_PRODUCT_TITLE' | translate }}</span>
  <form [formGroup]="reviewForm" (ngSubmit)="onSubmit()">
    <div class="form-area">
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>{{
              'PRODUCTS_PAGE.REVIEW_PRODUCT.SELECT_REVIEW_LABEL' | translate
            }}</mat-label>
            <mat-select formControlName="reasons" multiple>
              <mat-option
                *ngFor="let productIssue of listOfIssuesWithProduct"
                [value]="productIssue.arabicText"
              >
                {{ isEnglishLanguage ? productIssue.englishText : productIssue.arabicText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.REVIEW_NOTES' | translate }}</mat-label>
            <textarea
              matInput
              formControlName="content"
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="6"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-xs-12 star-rating">
          <mat-label>{{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.PRICE_REVIEW' | translate }}</mat-label>
          <div class="star-container">
            <button
              type="button"
              mat-icon-button
              color="accent"
              *ngFor="let ratingId of ratingArr; index as i"
              [id]="'star_' + i"
              (click)="setRatingValue('priceRating', i + 1)"
            >
              <mat-icon>
                {{ showIcon('priceRating', i) }}
              </mat-icon>
            </button>
          </div>
          <p class="rating-value">{{ formControl.priceRating.value }} / 5</p>
        </div>
        <div class="col-lg-6 col-xs-12 star-rating">
          <mat-label>{{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.PRODUCT_REVIEW' | translate }}</mat-label>
          <button
            type="button"
            mat-icon-button
            color="accent"
            *ngFor="let ratingId of ratingArr; index as i"
            [id]="'star_' + i"
            (click)="setRatingValue('productRating', i + 1)"
          >
            <mat-icon>
              {{ showIcon('productRating', i) }}
            </mat-icon>
          </button>
          <p class="rating-value">{{ formControl.productRating.value }} / 5</p>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="btn primary-selection" [disabled]="submitted" type="submit">
        {{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.SUBMIT' | translate }}
      </button>
      <button class="btn secondary-selection" type="button" (click)="onClose()">
        {{ 'PRODUCTS_PAGE.REVIEW_PRODUCT.CANCEL' | translate }}
      </button>
    </div>
  </form>
</section>
